import React from "react"
import { URL_DOWNLOAD } from "@constants/urls"

type Props = {
  className?: string
  secondary?: boolean
  primary?: boolean
  text?: string
  url?: string
}

export const ButtonGetTheApp = ({
  className,
  secondary,
  primary,
  text,
  url = URL_DOWNLOAD,
}: Props) => (
  <div className={`button-get-the-app ${className || ""}`}>
    <a
      href={url}
      className={`btn btn-download btn-elevate ${
        primary ? "btn-primary" : ""
      } ${secondary ? "btn-secondary" : ""}`}
    >
      {text || "Get the App"}
    </a>
  </div>
)
