import React from "react"
import { URL_GOOGLE_PLAY } from "@constants/urls"

import DownloadGoogleWhite from "./download-google-white.svg"
import DownloadGoogleBlack from "./download-google-black.svg"

type Props = {
  className?: string
  type?: string
}

export const ButtonDownloadGoogle = ({ className, type }: Props) => {
  return (
    <div className={className}>
      <a href={URL_GOOGLE_PLAY}>
        {type === "dark" || type === "black" ? (
          <DownloadGoogleBlack />
        ) : (
          <DownloadGoogleWhite />
        )}
      </a>
    </div>
  )
}
