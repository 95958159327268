import React from "react"
import {
  ButtonGetTheApp,
  ButtonDownloadApple,
  ButtonDownloadGoogle,
} from "@components/buttons"
import "./style.scss"

type Props = {
  className?: string
  type?: "white" | "light" | "black" | "dark"
  primary?: boolean
  secondary?: boolean
  platformsOnly?: boolean
  platform?: "ios" | "android" | "both"
  text?: string
  url?: string
}

export const CallToActionButtons = ({
  className,
  type,
  primary,
  secondary,
  platformsOnly,
  platform = "both",
  text,
  url,
}: Props) => (
  <div className={`call-to-action-buttons ${className || ""}`}>
    <div
      className={`cta-button-get ${
        platformsOnly ? "cta-platforms-only" : "cta-show-on-breakpoint"
      }`}
    >
      <ButtonGetTheApp
        primary={primary}
        secondary={secondary}
        text={text}
        url={url}
      />
    </div>
    <div
      className={`cta-button-platforms ${
        platformsOnly ? "cta-platforms-only" : "cta-show-on-breakpoint"
      }`}
    >
      {platform === "ios" || platform === "both" ? (
        <ButtonDownloadApple className="ios" type={type} />
      ) : null}
      {platform === "android" || platform === "both" ? (
        <ButtonDownloadGoogle className="android" type={type} />
      ) : null}
    </div>
  </div>
)

export const GetStartedButton = ({ primary, secondary, text, url }: Props) => (
  <div className="cta-button-get">
    <ButtonGetTheApp
      primary={primary}
      secondary={secondary}
      text={text}
      url={url}
    />
  </div>
)
