import React from "react"

type Props = {
  className?: string
}

export const HomepageScreenshotsImage = ({ className }: Props) => (
  <div className={className}>
    <picture>
      <source
        media="(max-width: 1055px)"
        width="342"
        height="268"
        srcSet="/img/2022q3/hero/homepage/mobile_v2.png, /img/2022q3/hero/homepage/mobile_v2@2x.png 2x, /img/2022q3/hero/homepage/mobile_v2@3x.png 3x"
      />
      <source
        media="(min-width: 1056px)"
        width="500"
        height="472"
        srcSet="/img/2022q3/hero/homepage/desktop_v2.png, /img/2022q3/hero/homepage/desktop_v2@2x.png 2x, /img/2022q3/hero/homepage/desktop_v2@3x.png 3x"
      />
      <img
        src="/img/2022q3/hero/homepage/mobile_v2.png"
        width="342"
        height="268"
        alt="ATM app with ant"
      />
    </picture>
  </div>
)

export const HomepagePersonalFinanceImage = ({ className }: Props) => (
  <div className={className}>
    <picture>
      <img
        src="/img/personal-finance@3x.png"
        width="611"
        height="373"
        alt="ATM Personal Finance"
      />
    </picture>
  </div>
)

export const HomepageATMInstantCashRewardsImage = ({ className }: Props) => (
  <div className={className}>
    <picture>
      <img
        src="/img/atm-instant-cash-rewards@3x.jpg"
        width="714"
        height="546"
        alt="ATM Instant Cash & Rewards"
      />
    </picture>
  </div>
)
