import React from "react"
import { URL_APP_STORE } from "@constants/urls"

import DownloadAppleWhite from "./download-apple-white.svg"
import DownloadAppleBlack from "./download-apple-black.svg"

type Props = {
  className?: string
  type?: string
}

export const ButtonDownloadApple = ({ className, type }: Props) => {
  return (
    <div className={className}>
      <a href={URL_APP_STORE}>
        {type === "dark" || type === "black" ? (
          <DownloadAppleBlack />
        ) : (
          <DownloadAppleWhite />
        )}
      </a>
    </div>
  )
}
