import React from "react"

type Props = {
  className?: string
}

export const MainBlockInstantCashImage = ({ className }: Props) => (
  <div className={className}>
    <picture>
      <source
        width="555"
        height="416"
        srcSet="/img/2024q3/main-blocks/ant-with-card.png, /img/2024q3/main-blocks/ant-with-card@2x.png 2x, /img/2024q3/main-blocks/ant-with-card@3x.png 3x"
      />
      <img
        src="/img/2024q3/main-blocks/ant-with-card.png"
        width="327"
        height="245"
        alt="Ants with cash card"
      />
    </picture>
  </div>
)

export const MainBlockInstantCashImage2 = ({ className }: Props) => (
  <div className={className}>
    <picture>
      <img
        src="/img/atm-instant-cash-50@3x.png"
        width="608"
        height="469"
        alt="ATM Instant Cash $50"
      />
    </picture>
  </div>
)

export const MainBlockTrackYourSpendingImage = ({ className }: Props) => (
  <div className={className}>
    <picture>
      <img
        src="/img/track-spending-screenshot@3x.png"
        width="323"
        height="547"
        alt="Track your spending"
      />
    </picture>
  </div>
)
